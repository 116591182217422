import { useEffect, useState } from "react";
import { Loading } from "components";
import { Company, convertDateFormat } from "helpers";
import { useCompanyManagementContext } from "pages/CompanyManagement/utils/CompanyManagementContext";
import { CompanyTableDataOptions } from "./components";

interface CompanyTableDataProps {
  onFlag: (company: Company) => void;
}

export const CompanyTableData = ({ onFlag }: CompanyTableDataProps) => {
  const { companies, isFetching, isLoading } = useCompanyManagementContext();

  return (
    <>
      {!!companies && companies.length > 0 ? (
        companies.map((company, index) => (
          <tr key={index} className="h-[100px] max-h-[100px] break-words border-y text-center">
            <td className="w-full text-center text-sm">{company.id}</td>
            <td className="w-full text-center text-sm">{company.name}</td>
            <td className="w-full text-center text-sm">{company.email}</td>
            <td className="w-full text-center text-sm">{company.phone_number}</td>
            <td className="w-full text-center text-sm">{company.translation?.EN?.address ?? "N/A"}</td>
            <td className="w-full text-center text-sm">{company.status ? "Active" : "Inactive"}</td>
            <td className="w-full text-center text-sm">
              <CompanyTableDataOptions company={company} onFlag={onFlag} />
            </td>
          </tr>
        ))
      ) : isFetching ? (
        <tr>
          <td colSpan={6}>
            <Loading absolute className="inset-0" />
          </td>
        </tr>
      ) : (
        <tr>
          <td colSpan={6} className="text-center text-gray-500">
            No companies available
          </td>
        </tr>
      )}

      {isLoading && (
        <tr>
          <td colSpan={6}>
            <Loading absolute className="inset-0" />
          </td>
        </tr>
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import { useAppDispatch } from "app/hooks";
import { Table, Loading } from "components";
import { ParkingTableHeadings } from "../ParkingTableHeadings/ParkingTableHeadings";
import { ParkingTableData } from "../ParkingTableData";
// import { useGetCompaniesQuery } from "services/companyApi"; // ❌ Commented Out
import { ParkingState } from "helpers";
import dummyParkings from "helpers/dummyParkings";

export const ParkingTable = () => {
  const dispatch = useAppDispatch();
  const isDev = process.env.NODE_ENV === "development";

  // ✅ Only Use Dummy Data
  const [parkings, setParkings] = useState<ParkingState[]>(dummyParkings);
  const [isFetching, setIsFetching] = useState(false); // Simulate no API fetching
  const [error, setError] = useState(null); // No API errors since we're not calling it

  // const { data: companyData, isLoading: isFetching, error, isSuccess } = useGetCompaniesQuery({ page }); // ❌ Commented Out

  const page = 1; // Static page since we are not paginating dummy data
  const totalPages = 1; // Dummy data has no pagination

  const onFlag = (parking: ParkingState) => {
    console.log("Flagging company:", parking);
  };

  return (
    <>
      {isFetching ? (
        <Loading />
      ) : error ? (
        <p className="text-red-500">Failed to load companies. Please try again.</p>
      ) : parkings.length > 0 ? (
        <Table
          headings={<ParkingTableHeadings />}
          data={<ParkingTableData parkings={parkings} isLoading={isFetching} onFlag={onFlag} />}
          page={page}
          pageCount={totalPages}
          onPageChange={() => {}} // No-op since we're using static dummy data
        />
      ) : (
        <p>No companies found.</p>
      )}
    </>
  );
};

export default ParkingTable;

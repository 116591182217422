import { OptionProps } from "components";
import { CompanyInUser } from "./interfaces";
import { Language } from "./types";

export const transformPhoneNumber = (phoneNumber: string | null) => {
  if (phoneNumber !== null) {
    // Remove the '+' symbol and any non-numeric characters from the phone number
    let numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    /*
     * Extract the first 3 digits (country code) and replace them with 0, followed by the next 3 digits (area code),
     * then a slash, followed by the next 3 digits (first part of the local number) and adding the 0,
     * followed by a hyphen, and finally the last 3 digits (second part of the local number)
     */

    let transformedPhoneNumber = `0${numericPhoneNumber.slice(3, 5)}/${numericPhoneNumber.slice(
      5,
      8
    )}-${numericPhoneNumber.slice(8, 11)}`;

    return transformedPhoneNumber;
  }
  return phoneNumber;
};

// adding +389 and removing the first number -> 0
export const transfromToE164 = (phoneNumber: string): string => `+389${phoneNumber.substring(1)}`;

// Function to convert the date format
export const convertDateFormat = (inputDate: string) => {
  // Check if the input date is null or undefined
  if (!!!inputDate) return "";

  // Split the date and time parts
  const [datePart, timePart] = inputDate.split(" ");

  // Split the date by "-"
  const [year, month, day] = datePart.split("-");

  // Reformat the date as "DD.MM.YYYY"
  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};

export const transformCompanyToOptions = (companies: CompanyInUser[]): OptionProps[] =>
  companies.map(({ id, name }) => ({ value: id, label: name }));

export const transofrmLanguagesToOptions = (languages: Language[]): OptionProps[] =>
  languages.map((language) => ({ value: language }));

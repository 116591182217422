import { CompanyHeader } from "./components/CompanyHeader/CompanyHeader";
import { CompanyTable } from "./components";

export const ComppanyManagement = () => {
  return (
    <>
      <CompanyHeader />
      <CompanyTable />
    </>
  );
};

import { ParkingState } from "helpers"; // Assuming the Parking interface is in helpers/interfaces.ts

const dummyParkings: ParkingState[] = [
  {
    unique_number: "P001",
    parking_name: "Downtown Parking",
    company_name: "Tech Innovations",
    parking_type: "Open",
    municipality: "Central District",
    total_parking_slots: 150,
  },
  {
    unique_number: "P002",
    parking_name: "Green City Parking",
    company_name: "Green Energy Solutions",
    parking_type: "Closed",
    municipality: "Westside",
    total_parking_slots: 80,
  },
  {
    unique_number: "P003",
    parking_name: "Skyline Garage",
    company_name: "NextGen Robotics",
    parking_type: "Closed",
    municipality: "Uptown",
    total_parking_slots: 200,
  },
  {
    unique_number: "P004",
    parking_name: "AI Mall Parking",
    company_name: "AI Pioneers",
    parking_type: "All",
    municipality: "Mall District",
    total_parking_slots: 250,
  },
  {
    unique_number: "P005",
    parking_name: "Smart City Underground",
    company_name: "Smart City Solutions",
    parking_type: "Closed",
    municipality: "Business Park",
    total_parking_slots: 120,
  },
];

export default dummyParkings;

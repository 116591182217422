import { useEffect, useState } from "react";
import { Button, CardHeader, ValidationMessage } from "components";
import { UserState, Step } from "helpers";
// import { AWSAuth } from "authentication";
import { useAuthContext } from "context";
import { VerifyPhoneNumberForm } from "features";

interface AuthVerifyPhoneNumberProps extends Pick<UserState, "user"> {
  variant?: "auth" | "profile";
}

export const AuthVerifyPhoneNumber = ({ user, variant = "auth" }: AuthVerifyPhoneNumberProps) => {
  const { setAuth } = useAuthContext();

  const [step, setStep] = useState<Step>(0);
  const [error, setError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [paragraph, setParagraph] = useState("");

  const onSet2FA = async () => {
    try {
      setDisabled(() => true);
      // await AWSAuth.rememberDevice();
      // await AWSAuth.setPreferredMFA(user, "SMS_MFA");
      setAuth("success");
    } catch (err: any) {
      console.error(err);
      setError(err.message ? err.message : err);
    } finally {
      setDisabled(() => false);
    }
  };

  useEffect(() => {
    if (step === 0) {
      setTitle("Please verify your phone number");
      setParagraph("You will no longer see this window after the first sign in.");
    } else {
      setTitle("Your phone number has been verified.");
      setParagraph("");
    }
  }, [step]);

  const set2FALater = (
    <span className="cursor-pointer text-xs leading-6 hover:underline" onClick={() => setAuth("success")}>
      Set 2FA later
    </span>
  );

  return (
    <>
      {step === 0 ? (
        <div className="flex flex-col gap-2">
          <CardHeader title={title} paragraph={paragraph} />
          <VerifyPhoneNumberForm onContinue={() => setStep(1)} user={user} variant={variant} cancel={set2FALater} />
        </div>
      ) : (
        <div className="flex flex-col gap-20">
          <div className="flex-1"></div>
          <div className="px-20">
            <CardHeader withLanguage={false} title={title} paragraph={paragraph} />
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-1">
              <div className="flex flex-1 items-center justify-center">{set2FALater}</div>
              <div className="flex-1">
                <Button disabled={disabled} onClick={onSet2FA}>
                  Set 2FA
                </Button>
              </div>
            </div>
            <ValidationMessage>{error}</ValidationMessage>
          </div>
        </div>
      )}
    </>
  );
};

import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Modal, ModalProps } from "features";
import { twMerge } from "tailwind-merge";
import { Button, InputStyledDiv, Textarea, Loading } from "components";
import { ILog, INote, convertDateFormat } from "helpers";
// import { useCreateCompanyNoteMutation, useGetCompanyLogsQuery, useGetCompanyNotesQuery, useMarkCompanyNoteAsDoneMutation } from "services";
import { Message } from "features/Modal";

export type ModalStep = "initial" | "success";

interface CompanyNotesModalProps extends ModalProps {
  companyId: string;
}

export const CompanyNotesModal = ({ open, setOpen, companyId }: CompanyNotesModalProps) => {
  const [step, setStep] = useState<ModalStep>("initial");
  const [note, setNote] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  // const [createNote, createNoteResult] = useCreateCompanyNoteMutation();
  // const [markAsDone, markAsDoneResult] = useMarkCompanyNoteAsDoneMutation();
  // const notes = useGetCompanyNotesQuery(companyId);
  // const logs = useGetCompanyLogsQuery(companyId);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => setNote(event.target.value);

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setNote("");
    // await createNote({ companyId, note });
    setStep("success");
  };

  // const markNoteAsDone = async ({ note_id, uuid }: { note_id: number; uuid: string }) => {
  //   await markAsDone({ note_id, uuid });
  //   notes.refetch();
  // };

  useEffect(() => {
    setDisabled(!note);
  }, [note]);

  // useEffect(() => {
  //   if (step === "success" && open) {
  //     const refetchNotes = async () => {
  //       await notes.refetch();
  //       setTimeout(() => {
  //         setStep("initial");
  //       }, 2000);
  //     };
  //     refetchNotes();
  //   }
  // }, [step]);

  return (
    <Modal withActions={false} withClose={step === "initial"} open={open} setOpen={setOpen}>
      {step === "initial" && (
        <div className="relative flex flex-col gap-y-6">
          <form onSubmit={onSubmitHandler}>
            <div className="flex flex-col gap-y-3">
              <h2 className="mb-3">New Company Note*</h2>
              <Textarea placeholder="Add your note here" value={note} onChange={handleChange} />
            </div>
            <div className="mt-5 grid gap-3 sm:grid-flow-row-dense sm:grid-cols-2">
              <div className="flex items-center justify-center">
                <span className="cursor-pointer text-xs leading-6 text-secondary" onClick={() => setOpen(false)}>
                  Cancel
                </span>
              </div>
              <Button type="submit" disabled={disabled}>
                Save Changes
              </Button>
            </div>
          </form>

          {/* List of company notes */}
          {/* {notes.isSuccess && notes.data.notes.length > 0 && (
            <>
              <h2 className="mb-3">Company Notes</h2>
              <ul className="flex max-h-48 flex-col gap-y-2 overflow-y-auto">
                {notes.data.notes.map(({ id, note, mark_as_done, administrator: { uuid } }: INote) => (
                  <li key={id}>
                    <InputStyledDiv className="text-center">
                      <div className="flex">
                        <span className="flex flex-1 items-center justify-center">{note}</span>
                        {!mark_as_done && (
                          <Button onClick={() => markNoteAsDone({ note_id: id, uuid })}>Mark as done</Button>
                        )}
                      </div>
                    </InputStyledDiv>
                  </li>
                ))}
              </ul>
            </>
          )} */}

          {/* List of company logs */}
          {/* {logs.isSuccess && logs.data.logs.length > 0 && (
            <>
              <h2 className="mb-3">Company Logs</h2>
              <ul className="flex max-h-48 flex-col gap-y-2 overflow-y-auto">
                {logs.data.logs.map(({ id, created_at: date, log }: ILog) => (
                  <li key={id} className="flex gap-2">
                    <div className="w-fit">
                      <InputStyledDiv className="flex h-full text-center">
                        {convertDateFormat(date || "")}
                      </InputStyledDiv>
                    </div>
                    <div className="w-full">
                      <InputStyledDiv className="text-center">{log}</InputStyledDiv>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
          {(logs.isFetching || logs.isLoading || notes.isFetching || notes.isLoading || markAsDoneResult.isLoading || createNoteResult.isLoading) && <Loading absolute />} */}
        </div>
      )}
      {step === "success" && <Message message="A company note has been added" />}
    </Modal>
  );
};

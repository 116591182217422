import { CompanyInUser, CompanySort, Language, RoleSort, UserSort, UserStatus, UserType, UserTypeId } from "helpers";

export const LANGUAGES: Language[] = ["EN", "MK", "AL"];

export const COMPANIES: CompanyInUser[] = [
  {
    id: "elektros-oftb-111a-222b-334455c66d77",
    name: "Elektrosoft",
  },
  {
    id: "mkhostmk-00tb-111a-222b-334455c66d77",
    name: "MKHost",
  },
  {
    id: "hoyotech-00tb-111a-222b-334455c66d77",
    name: "HoyoTech",
  },
];

export const CREATE_USER_TYPES: UserType[] = [
  {
    id: 2,
    name: "Super Admin",
  },
  {
    id: 3,
    name: "Admin",
  },
];

const USER_TYPES: { [key: number]: string } = {
  1: "Master Admin",
  2: "Super Admin",
  3: "Admin",
};

export const isMasterAdmin = (id: UserTypeId) => id === 1;
export const isSuperAdmin = (id: UserTypeId) => id === 2;
export const isAdmin = (id: UserTypeId) => id === 3;

export const getUserTypeName = (id: UserTypeId) => USER_TYPES[id];

export const FILTER_USER_TYPE: UserType[] = [
  {
    id: 1,
    name: "Master",
  },
  {
    id: 2,
    name: "Super",
  },
  {
    id: 3,
    name: "Admin",
  },
  {
    id: undefined!,
    name: "All",
  },
];

export const STATUSES: UserStatus[] = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Inactive",
  },
  {
    id: undefined!,
    name: "All",
  },
];

export const USER_SORTS: UserSort[] = [
  {
    id: "latest",
    name: "Latest",
  },
  {
    id: "name-a-z",
    name: "Name A-Z",
  },
  {
    id: "name-z-a",
    name: "Name Z-A",
  },
  {
    id: "company-a-z",
    name: "Company A-Z",
  },
  {
    id: "company-z-a",
    name: "Company Z-A",
  },
  {
    id: "last-login",
    name: "Last Sign in",
  },
];

export const COMPANY_SORTS: CompanySort[] = [
  {
    id: "latest",
    name: "Latest",
  },
  {
    id: "name-a-z",
    name: "Name A-Z",
  },
  {
    id: "name-z-a",
    name: "Name Z-A",
  },
  {
    id: "company-a-z",
    name: "Company A-Z",
  },
  {
    id: "company-z-a",
    name: "Company Z-A",
  },
  {
    id: "last-login",
    name: "Last Sign in",
  },
];

export const ROLE_SORTS: RoleSort[] = [
  {
    id: "latest",
    name: "Latest",
  },
  {
    id: "name-a-z",
    name: "Name A-Z",
  },
  {
    id: "name-z-a",
    name: "Name Z-A",
  },
];

export const getCompanyName = (company: CompanyInUser, length: number = 20) => {
  const companyName = company.name || company.id;

  return companyName.length > length ? `${companyName.slice(0, length)}...` : companyName;
};

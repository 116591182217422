import { Flag } from "components";
import { ParkingState } from "helpers";

interface ParkingTableDataOptionsProps {
  parking: ParkingState;
  onFlag: (parking: ParkingState) => void;
}

export const ParkingTableDataOptions = ({ parking, onFlag }: ParkingTableDataOptionsProps) => {
  return (
    <div className="flex items-center justify-center bg-primary/5 py-5">
      <Flag className={"text-primary"} onClick={() => onFlag(parking)} />
    </div>
  );
};

import { ChangeEvent, FormEvent, ReactNode, useEffect, useState } from "react";
import {
  ValidationMessage,
  ButtonVariant,
  InputWithButton,
  Button,
  InputWithButtonI,
  InputStatus,
  Loading,
} from "components";
import { twMerge } from "tailwind-merge";
import { UserState, storePhoneNumber, storePreferredMFA } from "helpers";
// import { confirmSignIn } from "@aws-amplify/auth"; // Updated import for Amplify 6
import { transfromToE164 } from "helpers";
import { useLazyGetAttributeCodeQuery, useUpdateProfileMutation, useVerifyAttributeMutation } from "services";

type SubmitType = "phoneNumber" | "code";

const initialData: InputWithButtonI = {
  value: "",
  status: "initial",
  error: "",
};

interface VerifyPhoneNumberFormProps extends Pick<UserState, "user"> {
  variant: "auth" | "profile";
  onContinue: () => void;
  cancel: ReactNode;
  onStatusChange?: ({ phoneNumber, code }: { phoneNumber: InputStatus; code: InputStatus }) => void;
}

const getError = (err: any) => {
  if (err.message) {
    return err.message; // Amplify 6 errors typically have a message property
  }
  return "ERROR";
};

export const VerifyPhoneNumberForm = ({
  user,
  onContinue,
  cancel,
  onStatusChange = () => {},
}: VerifyPhoneNumberFormProps) => {
  const [phoneNumber, setPhoneNumber] = useState<InputWithButtonI>(initialData);
  const [code, setCode] = useState<InputWithButtonI>(initialData);
  const [sent, setSent] = useState<boolean>(false);
  const [disabledPhoneNumber, setDisabledPhoneNumber] = useState<boolean>(true);
  const [disabledCode, setDisabledCode] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [updateUser] = useUpdateProfileMutation();

  const [
    getAttributeCode,
    {
      isSuccess: isSuccessGetAttribute,
      isError: isErrorGetAttribute,
      isLoading: isLoadingGetAttribute,
      isFetching: isFetchingGetAttribute,
      error: errorGetAttribute,
      data: dataGetAttribute,
    },
  ] = useLazyGetAttributeCodeQuery();

  const [
    verifyAttribute,
    {
      isSuccess: isSuccessVerifyAttribute,
      isError: isErrorVerifyAttribute,
      isLoading: isLoadingVerifyAttribute,
      error: errorVerifyAttribute,
      data: dataVerifyAttribute,
    },
  ] = useVerifyAttributeMutation();

  const returnPhoneNumberText =
    phoneNumber.status === "initial" ? "Send" : phoneNumber.status === "success" ? "Sent" : "Send again";

  const returnCodeText = code.status === "initial" ? "Confirm" : code.status === "success" ? "Confirmed" : "Try again";

  const returnButtonVariant = ({ status }: InputWithButtonI): ButtonVariant =>
    status === "initial" ? "bg-primary" : status === "success" ? "bg-success" : "bg-error";

  const resetState = () => {
    setPhoneNumber(initialData);
    setCode(initialData);
    setDisabledPhoneNumber(true);
    setDisabledCode(true);
    setSent(false);
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    if (name === "phoneNumber")
      setPhoneNumber((oldObject: InputWithButtonI) => ({
        ...oldObject,
        value,
      }));

    if (name === "code")
      setCode((oldObject: InputWithButtonI) => ({
        ...oldObject,
        value,
      }));
  };

  const onSubmitHandler = (event: FormEvent<HTMLFormElement | HTMLButtonElement>, submitType: SubmitType): void => {
    event.preventDefault();

    const phone_number = transfromToE164(phoneNumber.value);
    console.log(phone_number);
    // if (submitType === "phoneNumber" && phoneNumber.status !== "success") onVerifyPhoneNumber(phone_number);
    // if (submitType === "code" && code.status !== "success") onVerifyCode(code.value);
  };

  // const onVerifyPhoneNumber = async (phone_number: string) => {
  //   setDisabledPhoneNumber(true);
  //   try {
  //     // Update user phone number with Amplify 6
  //     await Auth.updateUserAttributes(user, { phone_number });
  //     await updateUser({ phone_number }); // Assuming this is still needed for your backend
  //     await getAttributeCode("phone_number"); // Trigger code generation
  //   } catch (err) {
  //     setPhoneNumber((oldObject) => ({
  //       ...oldObject,
  //       error: getError(err),
  //       status: "error",
  //     }));
  //     setDisabledPhoneNumber(false);
  //   }
  // };

  // useEffect(() => {
  //   if (isSuccessGetAttribute && !isLoadingGetAttribute && !isFetchingGetAttribute) {
  //     const resetMFA = async () => {
  //       try {
  //         storePhoneNumber(transfromToE164(phoneNumber.value));
  //         // Amplify 6: Disable MFA (equivalent to NOMFA)
  //         await Auth.setPreferredMFA(user, "SMS"); // Set to SMS first to allow verification
  //         await Auth.deletePreferredMFA(user); // Then disable MFA
  //         setPhoneNumber((oldObject) => ({
  //           ...oldObject,
  //           status: "success",
  //         }));
  //         setSent(true);
  //         setDisabledPhoneNumber(false);
  //       } catch (err) {
  //         setPhoneNumber((oldObject) => ({
  //           ...oldObject,
  //           error: getError(err),
  //           status: "error",
  //         }));
  //       }
  //     };
  //     resetMFA();
  //   }
  //   if (isErrorGetAttribute) {
  //     setPhoneNumber((oldObject) => ({
  //       ...oldObject,
  //       error: getError(errorGetAttribute),
  //       status: "error",
  //     }));
  //   }
  // }, [
  //   isSuccessGetAttribute,
  //   isErrorGetAttribute,
  //   isLoadingGetAttribute,
  //   isFetchingGetAttribute,
  //   errorGetAttribute,
  //   dataGetAttribute,
  // ]);

  // const onVerifyCode = async (code: string) => {
  //   setDisabledCode(true);
  //   try {
  //     // Verify the phone number with Amplify 6
  //     await Auth.verifyUserAttribute(user, "phone_number", code);
  //     setCode((oldObject) => ({
  //       ...oldObject,
  //       status: "success",
  //     }));
  //     setDisabledCode(false);
  //   } catch (err) {
  //     setCode((oldObject) => ({
  //       ...oldObject,
  //       error: getError(err),
  //       status: "error",
  //     }));
  //     setDisabledCode(false);
  //   }
  // };

  useEffect(() => {
    if (phoneNumber.error) {
      setPhoneNumber((oldObject) => ({
        ...oldObject,
        error: "",
      }));
    }
    setDisabledPhoneNumber(!phoneNumber.value);
  }, [phoneNumber.value]);

  useEffect(() => {
    if (code.error) {
      setCode((oldObject) => ({
        ...oldObject,
        error: "",
      }));
    }
    setDisabledCode(!code.value);
  }, [code.value]);

  useEffect(() => {
    setDisabled(phoneNumber.status !== "success" || code.status !== "success");
    onStatusChange({
      phoneNumber: phoneNumber.status,
      code: code.status,
    });
  }, [phoneNumber.status, code.status]);

  return (
    <div className="relative flex flex-col gap-y-7">
      {(isLoadingGetAttribute || isLoadingVerifyAttribute) && <Loading absolute />}
      <div className="flex flex-col gap-y-5">
        <form onSubmit={(event) => onSubmitHandler(event, "phoneNumber")}>
          <div className="flex flex-col">
            <InputWithButton
              inputProps={{
                id: "phoneNumber",
                name: "phoneNumber",
                value: phoneNumber.value,
                onChange: onChangeHandler,
                type: "tel",
                maxLength: 9,
                minLength: 9,
                pattern: "[0]{1}[0-9]{8}",
                placeholder: "07XXXXXXX",
                readOnly: phoneNumber.status === "success",
              }}
              buttonProps={{
                type: "submit",
                variant: returnButtonVariant(phoneNumber),
                disabled: disabledPhoneNumber,
                className: "min-w-[150px] max-w-[150px] px-2",
                children: returnPhoneNumberText,
              }}
            />
            {(sent || !!phoneNumber.error) && (
              <div className={twMerge("mt-1 flex", sent && !!phoneNumber.error ? "justify-between" : "self-start")}>
                <p className="cursor-pointer self-start text-xs leading-6" onClick={resetState}>
                  Change number
                </p>
                <ValidationMessage>{phoneNumber.error}</ValidationMessage>
              </div>
            )}
          </div>
        </form>
        <form onSubmit={(event) => onSubmitHandler(event, "code")}>
          <div className="flex flex-col">
            <div className="flex">
              <InputWithButton
                inputProps={{
                  id: "code",
                  name: "code",
                  value: code.value,
                  onChange: onChangeHandler,
                  placeholder: "Enter SMS code",
                  maxLength: 6,
                  minLength: 6,
                  readOnly: code.status === "success" || phoneNumber.status !== "success",
                }}
                buttonProps={{
                  type: "submit",
                  variant: returnButtonVariant(code),
                  disabled: disabledCode,
                  className: "min-w-[150px] max-w-[150px] px-2",
                  children: returnCodeText,
                }}
              />
            </div>
            <div className="mt-2 flex justify-end">
              <ValidationMessage>{code.error}</ValidationMessage>
            </div>
          </div>
        </form>
      </div>
      <div className="flex flex-1">
        <div className="flex flex-1 items-center justify-center">{cancel}</div>
        <div className="flex-1">
          <Button disabled={disabled} onClick={onContinue}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

// import { AWSAuth } from "authentication";
import { UserCognito } from "helpers";
import { useEffect, useState } from "react";

export const useUser2FA = () => {
  const [user, setUser] = useState<UserCognito>();
  const [has2FA, setHas2FA] = useState<boolean>(false);

  // useEffect(() => {
  //   const getUser = async () => {
  //     try {
  //       const authenticatedUser = await AWSAuth.currentAuthenticatedUser();
  //       setUser(authenticatedUser);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   getUser();
  // }, []);

  useEffect(() => {
    setHas2FA(user?.preferredMFA === "SMS_MFA");
  }, [user]);

  return { user, has2FA };
};

import { Flag } from "components";
import { Company } from "helpers";

interface CompanyTableDataOptionsProps {
  company: Company;
  onFlag: (company: Company) => void;
}

export const CompanyTableDataOptions = ({ company, onFlag }: CompanyTableDataOptionsProps) => {
  return (
    <div className="flex items-center justify-center bg-primary/5 py-5">
      <Flag className={company.status ? "text-primary" : "text-error"} onClick={() => onFlag(company)} />
    </div>
  );
};

import { setupListeners } from "@reduxjs/toolkit/query/react";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import profileReducer from "services/profileSlice";
import userReducer from "services/userSlice";
import roleReducer from "services/roleSlice";
import {
  profileApi,
  userApi,
  roleApi,
  logApi,
  noteApi,
  notificationApi,
  resourceApi,
  permissionApi,
  deviceApi,
  companyApi,
  parkingApi,
} from "services";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
// import storage from "redux-persist/lib/storage"; // localStorage

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["profile"], // will be persisted
  // blacklist: [], // profile will not be persisted
};

const reducer = combineReducers({
  profile: profileReducer,
  userSlice: userReducer,
  roleSlice: roleReducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [parkingApi.reducerPath]: parkingApi.reducer,
  [logApi.reducerPath]: logApi.reducer,
  [noteApi.reducerPath]: noteApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [resourceApi.reducerPath]: resourceApi.reducer,
  [permissionApi.reducerPath]: permissionApi.reducer,
  [deviceApi.reducerPath]: deviceApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      profileApi.middleware,
      userApi.middleware,
      roleApi.middleware,
      companyApi.middleware,
      parkingApi.middleware,
      logApi.middleware,
      noteApi.middleware,
      notificationApi.middleware,
      resourceApi.middleware,
      permissionApi.middleware,
      deviceApi.middleware,
    ]);
  },
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

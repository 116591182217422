// import { AWSAuth } from "authentication";
import { Button, CardHeader, ValidationMessage, Input } from "components";
import { useAuthContext } from "context";
import { UserState, storeUser } from "helpers";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";

interface AuthChangePasswordFormI {
  password: string;
  repeatPassword: string;
}

const initialData: AuthChangePasswordFormI = {
  password: "",
  repeatPassword: "",
};

interface AuthChangePasswordProps extends Pick<UserState, "user"> {}

export const AuthChangePassword = ({ user }: AuthChangePasswordProps) => {
  const { setAuth } = useAuthContext();
  const [data, setData] = useState<AuthChangePasswordFormI>(initialData);
  const [error, setError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;

    setData((oldObject: AuthChangePasswordFormI) => ({
      ...oldObject,
      [name]: value,
    }));
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setDisabled(() => true);

    if (data.password !== data.repeatPassword) {
      setError("Passwords are not matching");
      setDisabled(() => false);
      return;
    }
    try {
      // await AWSAuth.completeNewPassword(user, data.password);
      storeUser(user);
      setAuth("verify-phone-number");
    } catch (err: any) {
      console.error(err);
      setError(err.message ? err.message : err);
    } finally {
      setDisabled(() => false);
    }
  };

  useEffect(() => {
    setDisabled(!data.password || !data.repeatPassword);
    !!error && setError("");
  }, [data]);

  return (
    <>
      <CardHeader
        title="Change Password"
        paragraph="Please choose a new strong password with a minimum of 8 characters, including at least one small and one big
          letter, number, and a symbol (ex. . , $ % # @ !)"
      />
      <form onSubmit={onSubmitHandler}>
        <div className="space-y-4">
          {/* Input below is only for accessability reasons */}
          <Input className="hidden" autoComplete="username" />
          <Input
            id="password"
            type="password"
            name="password"
            value={data.password}
            placeholder="New password"
            onChange={onChangeHandler}
            error={!!error}
            autoComplete="new-password"
          />
          <Input
            type="password"
            name="repeatPassword"
            value={data.repeatPassword}
            placeholder="Repat password"
            onChange={onChangeHandler}
            error={!!error}
            autoComplete="new-password"
          />
        </div>
        <div className="mt-2 flex justify-end">
          <ValidationMessage>{error}</ValidationMessage>
        </div>
        <Button type="submit" className="mx-auto mt-5 px-16 sm:min-w-[200px]" disabled={disabled}>
          Change password
        </Button>
      </form>
    </>
  );
};

import { fetchAuthSession } from "aws-amplify/auth";
import { UserCognito, PreferredMFA, ChallengeName, UserAttributes } from "./cognitoTypes"; // Adjust path
import { UserType } from "helpers"; // Adjust path

export interface LocalStorage {
  username: string | null;
  token: string | null;
  phone_number: string | null;
  preferredMFA: string | null;
}

// Setters
export const storeUserName = (username: string): void => localStorage.setItem("username", username);
export const storeToken = (token: string): void => localStorage.setItem("token", token);
export const storePhoneNumber = (phone_number: string): void => localStorage.setItem("phone_number", phone_number);
export const storePreferredMFA = (preferredMFA: PreferredMFA): void =>
  localStorage.setItem("preferredMFA", preferredMFA);
export const storeUser = (user: UserCognito) => {
  storeUserName(user.username);
  storeToken(user.accessToken);
  storePhoneNumber(user.attributes.phone_number);
  storePreferredMFA(user.preferredMFA || "NOMFA");
};
export const storeUserType = (userType: UserType): void => localStorage.setItem("userType", JSON.stringify(userType));

// Getters
export const getUserName = (): string | null => localStorage.getItem("username") || null;
export const getToken = (): string | null => localStorage.getItem("token") || null;
export const getPhoneNumber = (): string | null => localStorage.getItem("phone_number") || null;
export const getPreferredMFA = (): string | null => localStorage.getItem("preferredMFA") || null;
export const getUser = (): LocalStorage => ({
  username: getUserName(),
  token: getToken(),
  phone_number: getPhoneNumber(),
  preferredMFA: getPreferredMFA(),
});
export const getUserType = (): UserType | null => JSON.parse(localStorage.getItem("userType") as string) || null;

// Removers
export const removeUserName = () => localStorage.removeItem("username");
export const removeToken = () => localStorage.removeItem("token");
export const removePhoneNumber = () => localStorage.removeItem("phone_number");
export const removePreferredMFA = () => localStorage.removeItem("preferredMFA");
export const removeUser = () => {
  removeUserName();
  removeToken();
  removePhoneNumber();
  removePreferredMFA();
};

export const refreshUser = async (): Promise<void> => {
  try {
    const session = await fetchAuthSession();
    if (!session.tokens) throw new Error("No session data");

    const payload = session.tokens.idToken?.payload;
    const accessToken = session.tokens.accessToken;
    const idToken = session.tokens.idToken;

    if (!payload || !accessToken || !idToken) throw new Error("Missing token data");

    const cognitoUsername = payload["cognito:username"];
    const email = payload.email;
    const username =
      typeof cognitoUsername === "string" ? cognitoUsername : typeof email === "string" ? email : "unknown"; // Fallback to "unknown" if both fail

    const user: UserCognito = {
      username,
      accessToken: accessToken.toString(),
      idToken: idToken.toString(),
      attributes: {
        sub: payload.sub as string,
        email: payload.email as string,
        phone_number: (payload.phone_number as string) || "",
        email_verified: payload.email_verified === "true",
        phone_number_verified: payload.phone_number_verified === "true",
      },
    };
    storeUser(user);
  } catch (error) {
    console.error(error);
    removeUser();
  }
};

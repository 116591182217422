import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { UserCognito, UserState, storeUser } from "helpers";
import { Button, CardHeader, ValidationMessage, Input } from "components";
import { Link } from "react-router-dom";
import { signIn, fetchAuthSession } from "aws-amplify/auth";
import { useAuthContext } from "context";

interface AuthLoginFormI {
  email: string;
  password: string;
}

const initialData: AuthLoginFormI = {
  email: "",
  password: "",
};

interface AuthLoginProps extends Pick<UserState, "setUser"> {}

export const AuthLogin = ({ setUser }: AuthLoginProps) => {
  const { setAuth } = useAuthContext();
  const emailRegex = /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{1,}$/i;
  const [data, setData] = useState<AuthLoginFormI>(initialData);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { name, value },
    } = event;
    setData((oldObject) => ({ ...oldObject, [name]: value }));
  };

  const storeAdditionalUserData = async (username: string, deviceName: string) => {
    localStorage.setItem("username", username);
    localStorage.setItem("deviceName", deviceName);
    const authSession = await fetchAuthSession();
    const deviceKey = (authSession.tokens?.accessToken?.payload?.device_key as string) || "";
    if (deviceKey) {
      localStorage.setItem("deviceKey", deviceKey);
    } else {
      console.warn("No device_key found in access token payload");
    }
  };

  const onSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisabled(true);

    try {
      const { isSignedIn, nextStep } = await signIn({
        username: data.email,
        password: data.password,
      });

      const session = await fetchAuthSession();
      if (!session.tokens) {
        throw new Error("No authentication tokens available after sign-in");
      }

      const payload = session.tokens.idToken?.payload;
      const accessToken = session.tokens.accessToken;
      const idToken = session.tokens.idToken;

      if (!payload || !accessToken || !idToken) {
        throw new Error("Missing required token data (payload, accessToken, or idToken)");
      }

      const deviceName = navigator.userAgent;
      const cognitoUsername = payload["cognito:username"];
      const username = typeof cognitoUsername === "string" ? cognitoUsername : data.email;

      const user: UserCognito = {
        username,
        accessToken: accessToken.toString(),
        idToken: idToken.toString(),
        attributes: {
          sub: payload.sub as string,
          email: payload.email as string,
          phone_number: (payload.phone_number as string) || "",
          email_verified: payload.email_verified === "true",
          phone_number_verified: payload.phone_number_verified === "true",
        },
        preferredMFA: nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE" ? "SMS_MFA" : "NOMFA",
        challengeName: nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE" ? "SMS_MFA" : undefined,
      };

      if (isSignedIn) {
        storeUser(user);
        setUser(user);
        setAuth("success");
      } else {
        switch (nextStep.signInStep) {
          case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
            await storeAdditionalUserData(data.email, deviceName);
            setUser(user);
            setAuth("2fa");
            break;
          case "RESET_PASSWORD":
            setAuth("change-password");
            break;
          default:
            throw new Error(`Unhandled sign-in step: ${nextStep.signInStep}`);
        }
      }
    } catch (err: any) {
      console.error(err);
      setError(err.message || "An error occurred during sign-in");
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    setDisabled(!emailRegex.test(data.email) || !data.password);
    if (error) setError("");
  }, [data]);

  useEffect(() => {
    setAuth("login");
  }, [setAuth]);

  return (
    <>
      <CardHeader title="Sign In" />
      <form onSubmit={onSubmitHandler}>
        <div className="space-y-4">
          <Input
            type="email"
            id="email"
            name="email"
            value={data.email}
            placeholder="Email"
            onChange={onChangeHandler}
            error={!!error}
            autoComplete="email"
          />
          <Input
            type="password"
            id="password"
            name="password"
            value={data.password}
            placeholder="Password"
            onChange={onChangeHandler}
            error={!!error}
            autoComplete="current-password"
          />
        </div>
        <div className="mt-2 flex items-center justify-between">
          <Link to="/reset-password" className="text-primary underline">
            Forgot password?
          </Link>
          <ValidationMessage>{error}</ValidationMessage>
        </div>
        <Button type="submit" className="mx-auto mt-5 px-16 sm:min-w-[200px]" disabled={disabled}>
          Sign in
        </Button>
      </form>
    </>
  );
};

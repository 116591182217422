import { useEffect, useState } from "react";
import { Loading } from "components";
import { ParkingState } from "helpers";
import { useCompanyManagementContext } from "pages/CompanyManagement/utils/CompanyManagementContext";
import { ParkingTableDataOptions } from "./components";

interface ParkingTableDataProps {
  parkings: ParkingState[];
  isLoading: boolean;
  onFlag: (company: ParkingState) => void;
}

export const ParkingTableData = ({ parkings, isLoading, onFlag }: ParkingTableDataProps) => {
  return (
    <>
      {parkings.length > 0 ? (
        parkings.map((parking, index) => (
          <tr key={index} className="h-[100px] border-y text-center">
            <td className="w-full text-center text-sm">{parking.unique_number}</td>
            <td className="w-full text-center text-sm">{parking.parking_name}</td>
            <td className="w-full text-center text-sm">{parking.company_name}</td>
            <td className="w-full text-center text-sm">{parking.parking_type} </td>
            <td className="w-full text-center text-sm">{parking.municipality} </td>
            <td className="w-full text-center text-sm">{parking.total_parking_slots} </td>
            <td className="w-full text-center text-sm">
              <ParkingTableDataOptions parking={parking} onFlag={onFlag} />
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td className="text-center text-gray-500">No companies available</td>
        </tr>
      )}

      {isLoading && (
        <tr>
          <td colSpan={3}>
            <Loading absolute className="inset-0" />
          </td>
        </tr>
      )}
    </>
  );
};

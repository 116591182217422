import { store } from "app/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { router } from "router";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "i18n.config";
import { Suspense } from "react";
import { Loading } from "components";
import { TokenRefreshProvider } from "context";
import { Amplify } from "aws-amplify";

let persistor = persistStore(store);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "5hu0mfdu4rd3q5h46desk5d6ug",
      userPoolId: "eu-central-1_w7ldYwEHE",
      loginWith: {
        email: true,
      },
    },
  },
});
export const App = () => (
  <Suspense
    fallback={
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    }
  >
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <TokenRefreshProvider>
          <RouterProvider router={router} />
        </TokenRefreshProvider>
      </PersistGate>
    </Provider>
  </Suspense>
);

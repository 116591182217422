import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { UserState, storeUser } from "helpers";
import { Button, ButtonVariant, CardHeader, InputWithButton, InputWithButtonI, ValidationMessage } from "components";
import { Modal } from "features";
import { Link } from "react-router-dom";
import { confirmSignIn } from "aws-amplify/auth";
import { useLazyGetProfileQuery, useRegisterDeviceMutation } from "services";
import { useAuthContext } from "context";

const initialData: InputWithButtonI = {
  value: "",
  status: "initial",
  error: "",
};

interface Auth2FAProps extends Pick<UserState, "user"> {}

export const Auth2FA = ({ user }: Auth2FAProps) => {
  const { setAuth } = useAuthContext();

  const [code, setCode] = useState<InputWithButtonI>(initialData);
  const [disabledCode, setDisabledCode] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [getProfile] = useLazyGetProfileQuery();
  const [registerDevice, { isLoading, isError, error }] = useRegisterDeviceMutation();

  const returnCodeText = code.status === "initial" ? "Confirm" : code.status === "success" ? "Confirmed" : "Try again";
  const returnButtonVariant = ({ status }: InputWithButtonI): ButtonVariant =>
    status === "initial" ? "bg-primary" : status === "success" ? "bg-success" : "bg-error";

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;
    setCode((oldObject) => ({
      ...oldObject,
      value,
    }));
  };

  const onSubmitHandler = (event: FormEvent<HTMLFormElement | HTMLButtonElement>): void => {
    event.preventDefault();
    if (code.status !== "success") onVerifyCode(code.value);
  };

  const onVerifyCode = async (code: string) => {
    try {
      setDisabledCode(true);
      await confirmSignIn({
        challengeResponse: code,
      });
      setCode((oldObject) => ({
        ...oldObject,
        status: "success",
      }));
    } catch (err: any) {
      console.error(err);
      setCode((oldObject) => ({
        ...oldObject,
        error: err.message || "Invalid code. Please try again.",
        status: "error",
      }));
    } finally {
      setDisabledCode(false);
    }
  };

  const sendDeviceData = async (username: string, deviceKey: string, deviceName: string) => {
    try {
      const result = await registerDevice({ username, deviceKey, deviceName }).unwrap();
      console.log("Device registration result:", result);
    } catch (error) {
      console.error("Error registering device:", error);
    }
  };

  const onContinueHandler = () => {
    setAuth("success");
  };

  useEffect(() => {
    if (code.error) {
      setCode((oldObject) => ({
        ...oldObject,
        error: "",
      }));
    }
    setDisabledCode(code.value.length !== 6);
  }, [code.value]);

  useEffect(() => {
    setDisabled(code.status !== "success");
    if (code.status === "success" && user) {
      storeUser(user);
      const username = localStorage.getItem("username") || "unknown";
      const deviceName = localStorage.getItem("deviceName") || navigator.userAgent;
      const deviceKey = localStorage.getItem("deviceKey") || "";

      if (!deviceKey) {
        console.warn("No deviceKey found in localStorage; device registration may fail");
      }

      console.log("2FA Success:", { user, username, deviceKey, deviceName });
      sendDeviceData(username, deviceKey, deviceName);
      getProfile();
    }
  }, [code.status, user]);

  return (
    <div className="space-y-5">
      <CardHeader
        title="Two-factor authentication"
        paragraph="You have activated 2FA, please enter the SMS code to continue."
      />
      <form onSubmit={onSubmitHandler}>
        <div className="flex flex-col">
          <div className="flex">
            <InputWithButton
              inputProps={{
                id: "code",
                name: "code",
                value: code.value,
                onChange: onChangeHandler,
                placeholder: "Enter SMS code",
                maxLength: 6,
                minLength: 6,
                readOnly: code.status === "success",
              }}
              buttonProps={{
                type: "submit",
                variant: returnButtonVariant(code),
                disabled: disabledCode || isLoading,
                className: "min-w-[150px] max-w-[150px] px-2",
                children: returnCodeText,
              }}
            />
          </div>
          <ValidationMessage>{code.error || (isError ? "Failed to register device" : "")}</ValidationMessage>
          <div className="mt-5 flex flex-1">
            {code.status !== "success" && (
              <div className="flex flex-1 items-center justify-center">
                <button type="button" className="text-sm text-primary underline" onClick={() => setIsModalOpen(true)}>
                  I can't find the SMS. Help!
                </button>
              </div>
            )}
            <div className="flex flex-1 justify-center">
              <Button
                disabled={disabled || isLoading}
                className={code.status === "success" ? "mx-auto mt-5 px-16 sm:min-w-[200px]" : undefined}
                onClick={onContinueHandler}
              >
                Enter PARKBER
              </Button>
            </div>
          </div>
        </div>
      </form>

      <Modal open={isModalOpen} setOpen={setIsModalOpen} onlyCloseButton closeButton="Close" withActions={false}>
        <h2 className="text-center text-xl font-semibold">Can't find the SMS?</h2>
        <p className="mt-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ut dui interdum erat molestie ornare.
          Proin ut faucibus arcu. Suspendisse et augue malesuada, scelerisque diam at, dapibus arcu. Vestibulum
          convallis aliquet nulla.
        </p>
      </Modal>
    </div>
  );
};

import { Language } from "helpers";
import { getToken, ListResponse, Order } from "helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "environment";
import { CompanyResponse } from "./apiHelpers"; // Smeni vo ParkingResponse koga kje ima

// Define the API service
export const parkingApi = createApi({
  reducerPath: "parkingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.dev.baseUrl}/catalog/parking/manage`,
    prepareHeaders: (headers) => {
      headers.set("authorization-token", getToken() || "");
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getParkings: builder.query<CompanyResponse, ListCompanyOptions>({
      query: ({ page = 0, pageSize = 10, search, sort, order }) => ({
        url: `list`,
        params: {
          page: page + 1,
          pageSize,
          search: search ? search.replace(/ /g, "-") : undefined,
          sort,
          order,
        },
      }),
    }),
  }),
});

export const { useGetParkingsQuery, useLazyGetParkingsQuery } = parkingApi;

// Define the query options
interface ListCompanyOptions {
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  order?: Order;
}

import { useAppDispatch } from "app/hooks";
import { Button, InputWithButton, Sort, Search } from "components";
import { COMPANY_SORTS, CompanySortItem } from "helpers";
import { useParkingManagementContext } from "pages/ParkingManagement/utils";
import { ChangeEvent, useEffect, useState } from "react";

export const ParkingHeader = () => {
  const dispatch = useAppDispatch();
  const { onSearch, onSort, params } = useParkingManagementContext();
  const [inputValue, setInputValue] = useState(params.search || "");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1536);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1536);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setInputValue(params.search || "");
  }, [params.search]);

  return (
    <>
      <div className="flex flex-col gap-3 md:flex-row md:items-center xl:flex-row 2xl:justify-evenly">
        {isSmallScreen ? (
          <div className="w-full">
            <div className="w-6/2 mb-2 flex items-center gap-3">
              <InputWithButton
                inputProps={{
                  placeholder: "Search Companies",
                  variant: "modal",
                  value: inputValue,
                  onChange: (event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
                  className: "border-primary focus:border-primary flex-1",
                }}
                buttonProps={{
                  children: <Search className="w-10" />,
                  className: "px-6",
                  onClick: () => onSearch(inputValue),
                }}
              />
            </div>
            <Sort
              label="Sort by:"
              values={COMPANY_SORTS}
              onSort={(sort) => onSort(sort as CompanySortItem)}
              selectedSort={params.sort as CompanySortItem}
            />
          </div>
        ) : (
          <>
            <div className="flex w-full flex-col gap-3 xl:flex-row 2xl:w-auto 2xl:flex-row">
              <div className="w-6/12">
                <InputWithButton
                  inputProps={{
                    placeholder: "Search Companies",
                    variant: "modal",
                    value: inputValue,
                    onChange: (event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
                    className: "border-primary focus:border-primary",
                  }}
                  buttonProps={{
                    children: <Search className="w-10" />,
                    className: "px-6",
                    onClick: () => onSearch(inputValue),
                  }}
                />
              </div>
              <Sort
                label="Sort by:"
                values={COMPANY_SORTS}
                onSort={(sort) => onSort(sort as CompanySortItem)}
                selectedSort={params.sort as CompanySortItem}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

import { useState } from "react";
import { Select, InputStyledDiv, Close, Info } from "components";
import { FilterItem, TableFilter, TableHeading, TableOptions } from "components/Table";
import { useCompanyManagementContext } from "pages/CompanyManagement/utils/CompanyManagementContext";
import { STATUSES } from "helpers";

export const CompanyTableHeadings = () => {
  const { params, onResetFilters, onSort, onFilterByStatus } = useCompanyManagementContext();

  const [showInfo, setShowInfo] = useState<boolean>(false);

  const resetFilters = (
    <div className="absolute -mt-1.5 flex flex-col">
      {showInfo && (
        <InputStyledDiv className="absolute -mt-4 ml-6 w-max bg-primary/5 p-0 text-xs font-thin">
          Clear All Filters
        </InputStyledDiv>
      )}
      <div onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} className="relative">
        <Info width={7} height={7} className="ml-4" />
      </div>
      <Close className="text-error-text" onClick={onResetFilters} />
    </div>
  );

  const headings: Array<JSX.Element | string> = [
    <>
      {resetFilters}
      <TableHeading onClick={() => console.log("")}>Unique Number (ID)</TableHeading>
    </>,
    <TableHeading onClick={(order) => onSort("name", order)} order={params.order || "DESC"}>
      Company Name
    </TableHeading>,
    <TableHeading onClick={(order) => onSort("email", order)}>Email</TableHeading>,
    <TableHeading onClick={(order) => onSort("phone_number", order)}>Phone Number</TableHeading>,
    <TableHeading onClick={(order) => onSort("address", order)}>Address</TableHeading>,
    <TableFilter
      onFilter={onFilterByStatus}
      values={STATUSES}
      label="Status"
      selectedFilter={params.status as FilterItem | undefined}
    />,
    <TableOptions>Options</TableOptions>,
  ];

  return (
    <>
      {headings.map((heading, index) => (
        <th
          key={index}
          scope="col"
          className="h-[100px] max-h-[100px] w-[200px] min-w-[200px] break-words py-4 text-sm font-semibold text-primary"
        >
          {heading}
        </th>
      ))}
    </>
  );
};

import React, { useState } from "react";
import { Table, Loading } from "components";
import { CompanyTableHeadings } from "../CompanyTableHeadings";
import { CompanyTableData } from "../CompanyTableData";
import { useCompanyManagementContext } from "pages/CompanyManagement/utils/CompanyManagementContext";
import { CompanyNotesModal } from "./components/CompanyNotesModal/CompanyNotesModal";
import { Company, UUID } from "helpers";

export const CompanyTable = () => {
  const { data, isLoading, isFetching, isSuccess, page, setPage, onPrefetchNext } = useCompanyManagementContext();
  const [openNotes, setOpenNotes] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<UUID>("");

  const onFlag = (Company: Company) => {
    const { company_id } = Company;
    setCompanyId(company_id);
    setOpenNotes(true);
  };

  return (
    <>
      {isSuccess && !!data ? (
        <>
          <Table
            headings={<CompanyTableHeadings />}
            data={<CompanyTableData onFlag={onFlag} />}
            page={page}
            pageCount={data?.totalPages}
            onPageChange={({ selected }) => setPage(selected)}
            onMouseEnter={() => onPrefetchNext(page + 1)}
          />
          {openNotes && <CompanyNotesModal open={openNotes} setOpen={setOpenNotes} companyId={companyId} />}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CompanyTable;

import { ParkingHeader } from "./components/ParkingHeader";
import { ParkingTable } from "./components/ParkingTable";

export const ParkingManagement = () => {
  return (
    <>
      <ParkingHeader />
      <ParkingTable />
    </>
  );
};

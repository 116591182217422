import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState, ReactNode } from "react";
import { useLazyGetParkingsQuery } from "services/parkingApi";
import { Company, Order } from "helpers";
import { CompanyResponse } from "services";
import { FilterItem } from "components/Table";

interface Params {
  search?: string;
  sort?: string;
  order?: Order;
  status?: string;
}

const initialParams: Params = {
  search: undefined,
  sort: undefined,
  order: undefined,
  status: undefined,
};

export interface ParkingManagementContextValues {
  companies: Company[] | undefined;
  data: CompanyResponse | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  params: Params;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  onSort: (sort: string, order?: Order) => void;
  onSearch: (search: string) => void;
  onFilterByStatus: (status?: FilterItem) => void; // ✅ Added function
  onResetFilters: () => void;
  onPrefetchNext: (page: number) => void;
  onFetch: (preferCacheValue?: boolean) => Promise<void>;
}

export const ParkingManagementContext = createContext<ParkingManagementContextValues | undefined>(undefined);

export const ParkingManagementProvider = ({ children }: { children: ReactNode }) => {
  const [trigger, result] = useLazyGetParkingsQuery();
  const [params, setParams] = useState<Params>(initialParams);
  const [page, setPage] = useState<number>(0);
  const pageSize = 5;

  useEffect(() => {
    onFetchHandler(true);
  }, [params, page]);

  const onSortHandler = (sort: string, order?: Order) => {
    setPage(0);
    setParams((oldParams) => ({ ...oldParams, sort, order }));
  };

  const onSearchHandler = (search: string) => {
    setPage(0);
    setParams(() => ({ ...initialParams, search }));
  };

  const onFilterByStatusHandler = (status?: FilterItem) => {
    setPage(0);
    setParams((oldParams) => ({
      ...oldParams,
      status: status ? String(status) : undefined,
    }));
  };

  const onResetFiltersHandler = () => {
    setPage(0);
    setParams(initialParams);
  };

  const onPrefetchNextHandler = (page: number) => {
    if (!!result.data && page < result.data.totalPages) trigger({ page, pageSize, ...params });
  };

  const onFetchHandler = async (preferCacheValue: boolean = false) => {
    try {
      await trigger({ page, pageSize, ...params }, preferCacheValue);
    } catch (error) {
      console.log(error);
    }
  };

  const values: ParkingManagementContextValues = {
    companies: result?.data?.companies,
    data: result?.data,
    isLoading: result.isLoading,
    isFetching: result.isFetching,
    isSuccess: result.isSuccess,
    params,
    page,
    setPage,
    onSort: onSortHandler,
    onSearch: onSearchHandler,
    onFilterByStatus: onFilterByStatusHandler, // ✅ Now added to context
    onResetFilters: onResetFiltersHandler,
    onPrefetchNext: onPrefetchNextHandler,
    onFetch: onFetchHandler,
  };

  return <ParkingManagementContext.Provider value={values}>{children}</ParkingManagementContext.Provider>;
};

export const useParkingManagementContext = () => {
  const context = useContext(ParkingManagementContext);

  if (!context) {
    throw new Error("useParkingManagement must be used within a ParkingManagementProvider!");
  }
  return context;
};

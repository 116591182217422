import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { getToken, refreshUser, storeUserType, signOut } from "helpers";
import { CompanyId } from "helpers";
import { useNavigate } from "react-router-dom";
import { useLazyGetProfileQuery, useLazyGetUsersQuery, useUpdateProfileMutation } from "services";

// Align with types.ts
export type Auth = "login" | "change-password" | "verify-phone-number" | "2fa" | "success";

type AuthContextValues = {
  auth: Auth;
  setAuth: Dispatch<SetStateAction<Auth>>;
};

export const AuthContext = createContext<AuthContextValues | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [getProfile, { isFetching, isSuccess, isLoading, isError, data }] = useLazyGetProfileQuery();
  const [getUsers] = useLazyGetUsersQuery();
  const [updateProfile] = useUpdateProfileMutation();
  const [auth, setAuth] = useState<Auth>("login");

  const hasForcePassword = !!getToken() && data?.confirmation_status === "FORCE_CHANGE_PASSWORD";

  useEffect(() => {
    const refresh = async () => {
      await refreshUser();
      if (getToken()) {
        navigate("/admin-management");
      }
    };
    refresh();
  }, [navigate]);

  useEffect(() => {
    if (auth === "success") {
      getProfile();
    }
  }, [auth, getProfile]);

  useEffect(() => {
    const getData = async (company_id: CompanyId) => {
      await getUsers({ company_id, page: 0, pageSize: 5 });
      await updateProfile({
        logged_in: true,
        confirmation_status: hasForcePassword ? "CONFIRMED" : undefined,
      });
      setTimeout(() => {
        navigate("/admin-management");
      }, 1000);
    };

    if (!isFetching && !isLoading && isSuccess && data) {
      storeUserType({ id: data.type.id, name: data.type.name });
      getData(data.company_id);
    }

    if (isError) {
      signOut().then(() => setAuth("login"));
    }
  }, [isFetching, isLoading, isSuccess, isError, data, navigate]);

  const values: AuthContextValues = {
    auth,
    setAuth,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider!");
  }
  return context;
};

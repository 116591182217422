import { Language } from "helpers";
import { getToken, ListResponse, Order } from "helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "environment";
import { CompanyResponse } from "./apiHelpers";

// Define the API service
export const companyApi = createApi({
  reducerPath: "companyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.dev.baseUrl}/catalog/company/manage`,
    prepareHeaders: (headers) => {
      headers.set("authorization-token", getToken() || "");
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getCompanies: builder.query<CompanyResponse, ListCompanyOptions>({
      query: ({ page = 0, pageSize = 5, search, sort, order }) => ({
        url: `list`,
        params: {
          page: page + 1, // API expects 1-based pagination
          pageSize,
          search: search ? search.replace(/ /g, "-") : undefined,
          sort,
          order,
        },
      }),
    }),
  }),
});

export const { useGetCompaniesQuery, useLazyGetCompaniesQuery, usePrefetch: useCompanyApiPrefetch } = companyApi;

// Define the query options
interface ListCompanyOptions {
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  order?: Order;
}
